import { createStore } from "vuex";
import defaultData from "./store-data";
import SecureLS from "secure-ls";
import actions from "./actions";

import {
  COMMIT_EXAM,
  COMMIT_LESSON,
  COMMIT_SCROLL_THROUGH_QUESTIONS_META,
  COMMIT_NOTICE_LOADING_TEXT,
  COMMIT_SELECTED_TOPIC,
  COMMIT_FEATURE_FLAG,
  COMMIT_CURRENT_QUESTION,
  COMMIT_TOPIC_AUTOSTART_CONFIG,
  COMMIT_STUDENT_AUTOSELECT,
  COMMIT_STEP_BY_STEP_RESPONSES,
  COMMIT_STEP_BY_STEP_MARKING_META,
  COMMIT_CLEAR_STEP_BY_STEP_QUESTION_DATA,
  COMMIT_STEP_BY_STEP_COMPLETE,
  COMMIT_ACTIVITIES_META,
  COMMIT_USE_ALTERNATIVE_SIGNALR_CONNECTION_STRING,
  COMMIT_UNRESTRICTED_ACTIVITIES,
  COMMIT_TUTOR_SESSION_TYPE,
  COMMIT_RUM_ENABLED,
  COMMIT_GCSE_QUERY_SESSION,
  COMMIT_GCSE_AVAILABLE_SUBJECTS,
  COMMIT_GCSE_SELECT_SKILL_SUBJECT,
  COMMIT_GCSE_AVAILABLE_AREAS,
  COMMIT_GCSE_SELECT_SKILL_AREA,
  COMMIT_GCSE_SELECT_CURRICULUM,
  COMMIT_GCSE_AVAILABLE_SKILLS,
  COMMIT_GCSE_SELECT_SKILL,
  COMMIT_GCSE_RETURNED_SKILLS,
  COMMIT_TEMP_FEARLESS_POINTS,
  COMMIT_FLP,
  COMMIT_SSO_ROUTE_TO,
  COMMIT_STUDENT_CAN_SELF_RATE,
  COMMIT_SELECTED_CURRICULUM
} from "@/constants/mutations";
import {
  CURRICULUM_DEFAULT,
  CURRICULUM_ELEVEN_PLUS,
  CURRICULUM_GCSE,
} from "@/constants/curriculumTypes";

let sls = null;
if (typeof localStorage !== "undefined") {
  sls = new SecureLS({ isCompression: false });
}

const store = createStore({
  state: Object.assign({}, defaultData),
  actions,
  mutations: {
    setAccessibilityFont(state, fontSize) {
      state.accessibilityFeatures.fontSize = fontSize;
    },
    setAccessibilityColor(state, colorOverlay) {
      state.accessibilityFeatures.colorOverlay = colorOverlay;
    },
    setAccessibilityModalActive(state, active) {
      state.accessibilityFeatures.modalActive = active;
    },
    initialiseStore(state, persistedState) {
      Object.assign(state, persistedState);
    },
    restoreDefaultData(state) {
      // eslint-disable-line no-unused-vars
      Object.assign(state, defaultData);
    },
    setIsRefreshingApplication(state, isRefreshingApplication) {
      state.isRefreshingApplication = isRefreshingApplication;
    },
    setRestoreRoute(state, restoreRoute) {
      if (
        restoreRoute !== "Desktop.Session.GroupMode" &&
        restoreRoute !== "Desktop.Home.Entrance"
      ) {
        state.restoreRoute = restoreRoute;
      }
    },
    setAutoSessionStartValues(state, autoSessionStartValues) {
      state.autoSessionStartValues = autoSessionStartValues;
    },
    setErrorOccurred(state, isError) {
      state.errorOccurred = isError;
    },
    setDeviceHasTouch(state, deviceHasTouch) {
      state.deviceHasTouch = deviceHasTouch;
    },
    setStudentAvatarProfile(state, studentAvatarProfile) {
      state.studentAvatarProfile = studentAvatarProfile;
    },
    setMode(state, mode) {
      state.mode = mode;
    },
    setSessionAbandoned(state) {
      state.session.sessionStatus = "Abandoned";
    },
    setHomeAuthToken(state, homeAuthToken) {
      state.homeAuthToken = homeAuthToken;
    },
    setHomeFamily(state, homeFamily) {
      state.homeFamily = homeFamily;
    },
    setCentre(state, centre) {
      state.centre = centre;
    },
    setCentreZone(state, centreZone) {
      state.centreZone = centreZone;
    },
    setStudentStartedAt(state, studentStartedAt) {
      state.studentStartedAt = studentStartedAt;
    },
    setStudent(state, student) {
      state.student = student;
    },
    setStudentProfile(state, studentProfile) {
      state.studentProfile = studentProfile;
    },
    setStudentAvatar(state, studentAvatar) {
      state.studentAvatar = studentAvatar;
    },
    setSkin(state, skin) {
      if (skin !== null && !state.avatarsAndSkinsEnabled) {
        return (state.skin = "SKIN1");
      }

      state.skin = skin;
    },
    setTutoredPanelEnabled(state, tutoredPanelEnabled) {
      state.tutoredPanelEnabled = tutoredPanelEnabled;
    },
    setAwardBalances(state, awardBalances) {
      state.awardBalances = awardBalances;
    },
    setAwardsGiven(state, awardsGiven) {
      state.awardsGiven = awardsGiven;
    },
    setSession(state, session) {
      state.session = session;

      if (session && !state.studentStartedAt) {
        state.studentStartedAt = Date.now();
      }
    },
    setActivity(state, activity) {
      state.activity = activity;
    },
    setActivityStartingSkills(state, activityStartingSkills) {
      state.activityStartingSkills = activityStartingSkills;
    },
    setActivityStartingSkill(state, activityStartingSkill) {
      state.activityStartingSkill = activityStartingSkill;
    },
    setActivityState(state, activityState) {
      state.activityState = activityState;
    },
    setIsBeingTutored(state, isBeingTutored) {
      state.isBeingTutored = isBeingTutored;
    },
    setActivityIsAssessmentMode(state, activityIsAssessmentMode) {
      state.activityIsAssessmentMode = activityIsAssessmentMode;
      if (
        activityIsAssessmentMode &&
        !state.assessmentActivityIds.includes(state.activity.activityId)
      ) {
        state.assessmentActivityIds.push(state.activity.activityId);
      }
    },
    setHasRemainingActivities(state, hasRemainingActivities) {
      state.hasRemainingActivities = hasRemainingActivities;
    },
    setSessionHasAssessmentActivities(state, sessionHasAssessmentActivities) {
      state.sessionHasAssessmentActivities = sessionHasAssessmentActivities;
    },
    setAssessmentActivityIds(state, assessmentActivityIds) {
      state.assessmentActivityIds = assessmentActivityIds;
    },
    setIsInGroupMode(state, isInGroupMode) {
      state.isInGroupMode = isInGroupMode;
    },
    setIsInSlowMode(state, isInSlowMode) {
      state.isInSlowMode = isInSlowMode;
    },
    setNetworkQuality(state, quality) {
      state.networkQuality = quality;
    },
    setNetworkSpeed(state, networkSpeed) {
      state.networkSpeed = networkSpeed;
    },
    setUserAgent(state, userAgent) {
      state.userAgent = userAgent;
    },
    setIsTablet(state, isTablet) {
      state.isTablet = isTablet;
    },
    setRemoteEventsSettings(state, settings) {
      state.remoteEventsSettings = settings;
    },
    setTutorEndedSession(state) {
      state.tutorEndedSession = true;
    },
    resetTutorEndedSession(state) {
      state.tutorEndedSession = false;
    },
    setTutorSessionId(state, id) {
      state.tutorSessionId = id;
    },
    setCanShootPhotograph(state, canShootPhotograph) {
      state.canShootPhotograph = canShootPhotograph;
    },
    setRemoteEventsId(state, remoteEventsId) {
      state.remoteEventsId = remoteEventsId;
    },
    setCurrentQuestionId(state, setCurrentQuestionId) {
      state.currentQuestionId = setCurrentQuestionId;
    },
    addSessionEvent(state, sessionEventData) {
      state.sessionEvents.push(sessionEventData);
    },
    updateSessionEvents(state, sessionEventsDataArray) {
      state.sessionEvents = sessionEventsDataArray;
    },
    updateNetworkSpeedDetails(state, networkSpeedDetails) {
      if (!networkSpeedDetails) {
        networkSpeedDetails = {
          minimum: null,
          maximum: null,
          average: null,
        };
      }

      state.networkSpeedDetails = networkSpeedDetails;
    },
    updateLastSentSessionEventTimeStamp(state, date) {
      state.lastSentSessionEventTimeStamp = date;
    },
    setTabInstanceId: (state, tabInstanceId) => {
      state.tabInstanceId = tabInstanceId;
    },
    setTabInvalid: (state, tabInvalid) => {
      state.tabInvalid = tabInvalid;
    },
    setFourModalActive: (state, active) => {
      state.fourModalActive = active;
    },
    setAvatarsAndSkinsEnabled: (state, enabled) => {
      state.avatarsAndSkinsEnabled = enabled;
    },
    setPreventSaveToLocalStorage: (state, prevent) => {
      state.preventSaveToLocalStorage = prevent;
    },
    setReturnRoute: (state, route) => {
      state.returnRoute = route;
    },
    setAvatarSelectorReturnRoute: (state, route) => {
      state.avatarSelectorReturnRoute = route;
    },
    [COMMIT_SELECTED_TOPIC]: (state, selectedTopic) => {
      state.selectedTopic = selectedTopic;
    },
    [COMMIT_NOTICE_LOADING_TEXT]: (state, text) => {
      state.noticeLoadingText = text;
    },
    [COMMIT_LESSON]: (state, text) => {
      state.lesson = text;
    },
    [COMMIT_SCROLL_THROUGH_QUESTIONS_META]: (state, lessons) => {
      state.scrollThroughQuestionsMeta = lessons;
    },
    [COMMIT_UNRESTRICTED_ACTIVITIES]: (state, activities) => {
      state.unrestrictedActivities = activities;
    },
    [COMMIT_EXAM]: (state, exam) => {
      state.exam = exam;
    },
    [COMMIT_FEATURE_FLAG]: (state, { flagName, bool }) => {
      state.featureFlags[flagName] = bool;
    },
    [COMMIT_CURRENT_QUESTION]: (state, question) => {
      state.currentQuestion = question;
    },
    setLatestError(state, err) {
      state.latestError = err;
    },
    [COMMIT_TOPIC_AUTOSTART_CONFIG]: (state, config) => {
      state.topicAutoStartConfig = config;
    },
    [COMMIT_STUDENT_AUTOSELECT]: (state, studentReference) => {
      state.studentAutoSelect = studentReference;
    },
    [COMMIT_STEP_BY_STEP_RESPONSES]: (state, response) => {
      const currentQuestionId = state?.currentQuestion?.sourceId || null;
      if (currentQuestionId !== null) {
        state.stepByStepState = state.stepByStepState || {};
        state.stepByStepState[currentQuestionId] =
          state.stepByStepState[currentQuestionId] || {};
        state.stepByStepState[currentQuestionId].response = response;
      }
    },
    [COMMIT_STEP_BY_STEP_MARKING_META]: (state, { score, isCorrect }) => {
      const currentQuestionId = state?.currentQuestion?.sourceId || null;
      if (currentQuestionId !== null) {
        state.stepByStepState[currentQuestionId].score = score;
        state.stepByStepState[currentQuestionId].isCorrect = isCorrect;
      }
    },
    [COMMIT_CLEAR_STEP_BY_STEP_QUESTION_DATA]: (state) => {
      state.stepByStepState = null;
    },
    [COMMIT_STEP_BY_STEP_COMPLETE]: (state) => {
      const currentQuestionId = state?.currentQuestion?.sourceId || null;
      if (currentQuestionId !== null) {
        state.stepByStepState[currentQuestionId].isComplete = true;
      }
    },
    [COMMIT_ACTIVITIES_META]: (state, activitiesMeta) => {
      state.activitiesMeta = activitiesMeta;
    },
    [COMMIT_USE_ALTERNATIVE_SIGNALR_CONNECTION_STRING]: (
      state,
      useAlternativeString
    ) => {
      state.useAlternativeSignalRConnectionString = useAlternativeString;
    },
    [COMMIT_TUTOR_SESSION_TYPE]: (state, type) => {
      state.tutorSessionType = type;
    },
    [COMMIT_RUM_ENABLED]: (state, rumEnabled) => {
      state.rumEnabled = rumEnabled;
    },
    [COMMIT_GCSE_QUERY_SESSION]: (state, session) => {
      state.gcseQuerySession = session;
    },
    [COMMIT_GCSE_AVAILABLE_SUBJECTS]: (state, subjects) => {
      state.gcseAvailableSubjects = subjects;
    },
    [COMMIT_GCSE_SELECT_SKILL_SUBJECT]: (state, subject) => {
      state.gcseSelectSkillSubject = subject;
    },
    [COMMIT_GCSE_AVAILABLE_AREAS]: (state, areas) => {
      state.gcseAvailableAreas = areas;
    },
    [COMMIT_GCSE_SELECT_SKILL_AREA]: (state, area) => {
      state.gcseSelectSkillArea = area;
    },
    [COMMIT_GCSE_SELECT_CURRICULUM]: (state, curriculum) => {
      state.gcseSelectCurriculum = curriculum;
    },
    [COMMIT_GCSE_AVAILABLE_SKILLS]: (state, skills) => {
      state.gcseAvailableSkills = skills;
    },
    [COMMIT_GCSE_SELECT_SKILL]: (state, skill) => {
      state.gcseSelectSkill = skill;
    },
    [COMMIT_GCSE_RETURNED_SKILLS]: (state, skills) => {
      state.gcseReturnedSkills = skills;
    },
    [COMMIT_TEMP_FEARLESS_POINTS]: (state, points) => {
      state.tempFearlessPoints = points;
    },
    [COMMIT_FLP]: (state, points) => {
      state.fearlessPointsTotal = points;
    },
    [COMMIT_SSO_ROUTE_TO]: (state, route) => {
      state.ssoRouteTo = route;
    },
    [COMMIT_STUDENT_CAN_SELF_RATE]: (state, canSelfRate) => {
      state.studentCanSelfRate = canSelfRate;
    },
    [COMMIT_SELECTED_CURRICULUM]: (state, selectedCurriculum) => {
      state.selectedCurriculum = selectedCurriculum;
    },
  },
  getters: {
    compass: (state) => state.compass,
    isRefreshingApplication: (state) => state.isRefreshingApplication,
    restoreRoute: (state) => state.restoreRoute,
    deviceHasTouch: (state) => state.deviceHasTouch,
    fearlessPointsTotal: (state) => state.fearlessPointsTotal,
    studentAvatarProfile: (state) => state.studentAvatarProfile,
    mode: (state) => state.mode,
    homeAuthToken: (state) => state.homeAuthToken,
    homeFamily: (state) => state.homeFamily,
    centre: (state) => state.centre,
    centreZone: (state) => state.centreZone,
    studentStartedAt: (state) => state.studentStartedAt,
    student: (state) => state.student,
    studentProfile: (state) => state.studentProfile,
    studentAvatar: (state) => state.studentAvatar,
    skin: (state) => state.skin,
    tutoredPanelEnabled: (state) => state.tutoredPanelEnabled,
    awardBalances: (state) => state.awardBalances,
    awardsGiven: (state) => state.awardsGiven,
    session: (state) => state.session,
    activity: (state) => state.activity,
    activityStartingSkills: (state) => state.activityStartingSkills,
    activityStartingSkill: (state) => state.activityStartingSkill,
    activityState: (state) => state.activityState,
    isBeingTutored: (state) => state.isBeingTutored,
    activityIsAssessmentMode: (state) => state.activityIsAssessmentMode,
    hasRemainingActivities: (state) => state.hasRemainingActivities,
    sessionHasAssessmentActivities: (state) =>
      state.sessionHasAssessmentActivities,
    assessmentActivityIds: (state) => state.assessmentActivityIds,
    isInGroupMode: (state) => state.isInGroupMode,
    networkQuality: (state) =>
      !state.networkQuality ? "Good" : state.networkQuality,
    isTablet: (state) => state.isTablet,
    remoteEventsSettings: (state) => state.remoteEventsSettings,
    tutorEndedSession: (state) => state.tutorEndedSession,
    canShootPhotograph: (state) => state.canShootPhotograph,
    remoteEventsId: (state) => state.remoteEventsId,
    tutorSessionId: (state) => state.tutorSessionId,
    errorOccurred: (state) => state.errorOccurred,
    isInSlowMode: (state) => state.isInSlowMode,
    currentQuestionId: (state) => state.currentQuestionId,
    autoSessionStartValues: (state) => state.autoSessionStartValues,
    isDefaultCurriculum: (state) =>
      state.student?.curriculumType === CURRICULUM_DEFAULT,
    isElevenPlus: (state) =>
      state.student?.curriculumType === CURRICULUM_ELEVEN_PLUS,
    isGCSE: (state) => state.student?.curriculumType === CURRICULUM_GCSE,
    isIndependentSession: (state) =>
      state.session?.sessionType === "Independent",
    showIndependentControls: (state, getters) =>
      getters.isIndependentSession || getters.isElevenPlus || getters.isGCSE,
    isTutorSession: (state) => {
      return state.session?.tutor?.tutorSessionId ? true : false;
    },
    tempFearlessPoints: (state) => state.tempFearlessPoints,
    studentCanSelfRate: (state) => state.studentCanSelfRate,
    selectedCurriculum: (state) => state.selectedCurriculum,
  },
});

store.subscribe((mutation, state) => {
  if (!state.preventSaveToLocalStorage && sls) {
    const jsonState = JSON.stringify(state);
    sls.set("compass-state-v2", jsonState);
  }
});

export default store;
